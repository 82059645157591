import { Config } from 'services/config';
import { Loader } from '@googlemaps/js-api-loader';

export class Google {
    static inject = [Config];
    _config;

    initialized = false;
    loaded = false;
    mapsApiKey;
    maps;
    _domParser;

    constructor(config) {
        this._config = config;

        this._initialize();
    }

    _initialize() {
        try {
            const data = this._config.value('googleIntegration');
            if (!data) return;
            const integration = JSON.parse(data);
            if (!integration || !integration.isConfigured) return;
            this.mapsApiKey = integration.mapsApiKey;
            this.initialized = true;
            this._domParser = new DOMParser();
        } catch (err) {
            console.log(err);
        }
    }

    async load() {
        if (this.loaded) return;
        try {
            this.loaded = true;
            const loader = new Loader({
                apiKey: this.mapsApiKey,
                version: 'weekly',
                libraries: ['places', 'marker', 'visualization', 'drawing']
            });
         
            await loader.load();
        } catch (err) {
            console.log(err);
            this.loaded = false;
        }
    }
}
